.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex2 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer {
    color: aliceblue;
    border-radius: 10px;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.789);
    margin-top: 100px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Animacion */

.contenedor-rotacion {
    display: inline-block;
  }
  
  .rotacion {
    transition: transform 0.5s ease;
  }
  
  .texto p {
    margin: 0; /* Asegurarse de que no haya margen dentro del párrafo */
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .texto .texto-hover {
    opacity: 0;
  }
  
  .contenedor-rotacion:hover .rotacion {
    transform: rotate(180deg);
  }
  
  .contenedor-rotacion:hover .texto .texto-original {
    opacity: 0;
  }
  
  .contenedor-rotacion:hover .texto .texto-hover {
    opacity: 1;
  }

  .texto-hover {
    display: none;
}

.contenedor-rotacion:hover .texto-original {
    display: none;
}

.contenedor-rotacion:hover .texto-hover {
    display: inline;
}
  