/* VideoPopup.css */
.popup {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.801);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    border-radius: 8px;
    position: relative;

  }

  .videoJokesweb {
    width: 700px;
  }
  
  .close {
    z-index: 111;
    position: relative;
    top: 50px;
    right: 10px;
    display: flex;
    flex-direction: row-reverse;
    width:inherit;
    cursor: pointer;
  }
  

  @media (max-width: 720px) {
    .videoJokesweb {
      width: 100%;
    }
  }