/*  GLOVAL */

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.flex3 {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

/*  */
@font-face {
    font-family: 'myFont';
    src: url('../../font/FingerPaint-Regular.ttf') format('truetype'); 
    font-weight: normal;
    font-style: normal;
}

.myFont {
    font-family: 'myFont', sans-serif;
    font-size: 35px;
}
/*  */

/* -------Logotipo--------- */

.developerText {
    font-family:Georgia, 'Times New Roman', Times, serif;
    color: rgb(96, 96, 96);
    display: inline-block;
    position: relative;
    transform: translateY(-50%);
    white-space: nowrap;
    opacity:0;
    transition: opacity 0.5s ease; 
}

.logo {
    width: 100px;
    background-color: aliceblue;
    border-radius: 100px;
}
.logo:hover {
    animation-name: gift;
    animation-duration: 1.9s; 
    animation-iteration-count: 1;
}
.logo:hover + .developerText {
    opacity: 1; 
}


@keyframes gift {
    from {}
    to {
        rotate: -360deg;
    }
}



/* ------------------------ */

.title {
    color: aliceblue;
    font-size: 50px;
    margin-left: 30px;
    margin-right: 30px;
    background-image: url("../../images/posit.jpg");
    background-position: center;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}


.board {
    background-image: url("../../images/fondoPizarra.jpg");
    background-size: 100% 100%;
    
    background-position: center;
    height: 450px;
    width: 700px;
    border-radius: 15px;
    box-shadow: 3px 3px 5px rgb(45, 30, 3);
    padding-left: 20px;
    padding-right: 20px;
}

.insideBoard {
    color: aliceblue;
    width: 80%;
    height: 80%;
}

.imgPosit {
    width: 200px;
}

.custom-input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 15px;
}


.custom-button {
    padding: 10px;
    font-size: 16px;
    background-color: rgb(33, 145, 35);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 3px 3px rgb(18, 80, 19);
    position: relative;

}.custom-button:hover {
    background-color: rgb(27, 113, 29);
    box-shadow:none;
    top: 3px;
    left: 3px;
}

.custom-button2 {
    padding: 10px;
    font-size: 16px;
    background-color: rgb(212, 61, 27);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 3px 3px rgb(159, 44, 18);
    position: relative;

}.custom-button2:hover {
    background-color: rgb(170, 55, 29);
    box-shadow:none;
    top: 3px;
    left: 3px;
}



.flechita {
    width: 40px;
}

/* --------------- */

@media screen and (max-width: 860px) {
    
    .board {
        width: 400px;
    }
    .myFont {
        font-size: 30px;
    }

    
}

@media screen and (max-width: 545px) {
    
    .board {
        width: 250px;
    }
    .myFont {
        font-size: 20px;
    }

    
}